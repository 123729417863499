.highlight {
  color: #39afd1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 320px) {
  .otp-input {
    width: 42px !important;
    height: 42px !important;
  }
}

@media only screen and (min-width: 375px) {
  .otp-input {
    width: 51px !important;
    height: 51px !important;
  }
}

@media only screen and (max-width: 425px) {
  .main-content {
    margin-left: 0 !important;
  }
  .page-fluid {
    /* padding-left: 24px; */
    margin: 0 -24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 425px) {
  .otp-input {
    width: 60px !important;
    height: 60px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .otp-input {
    width: 49px !important;
    height: 49px !important;
  }
  .main-content .container-fluid {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .otp-input {
    width: 69px !important;
    height: 69px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .otp-input {
    width: 68px !important;
    height: 68px !important;
  }
}
