/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "@ctrl/ngx-emoji-mart/picker";

body {
  font-family: Sora !important;
}

.main-root-container {
  max-width: 1500px;
  margin: 0 auto;
}

.otp-input {
  width: 49px;
  border-radius: 5px !important;
  border: solid 1px #313131bd !important;
}

.main-content .container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
  position: relative;
}

.ngx-pagination {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.avatar-img {
  background: #000 !important;
}

.swal2-confirm {
  background-color: #000 !important;
}

.swal2-popup {
  width: 400px !important;
}

.swal2-icon {
  border-color: #000000 !important;
  color: #000000 !important;
  //background-color: #000000 !important;
}

.dataTables_filter {
  text-align: right;
}

.dataTables_filter label {
  text-align: left;
}

#datatable_paginate {
  float: right;
}

.dropdown-item {
  cursor: pointer;
}

.dataTables_length,
.dataTables_info {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
  padding-top: 0 !important;
}

.dataTables_filter {
  margin-top: 15px;
  margin-right: 15px;
}

.dataTables_paginate {
  margin: 10px 15px;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.paginate_button {
  position: relative !important;
  margin-left: 0.25rem !important;
  margin-right: 0.25rem;
  line-height: 1.25 !important;
  background-color: #fff !important;
  border: 0 solid #e7eaf3 !important;
  border-radius: 0.3125rem !important;
}

.paginate_button.current {
  z-index: 3 !important;
  color: #fff !important;
  background: #8c8c8c !important;
  border-color: #8c8c8c !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #626161 !important;
  border-color: #626161 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
  background: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  box-shadow: 0 0 0 0 transparent !important;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #eff0f7 !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  padding-left: 10px !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding-left: 18px !important;
}

.ngx-pagination .current {
  background: #8c8c8c !important;
}

table.dataTable thead th,
table.dataTable thead td {
  font-size: 0.725rem !important;
  background-color: var(--bs-gray-100);
  color: var(--bs-gray-600);
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0 !important;
  border-top: 1px solid var(--bs-table-border-color);
}

ngx-skeleton-loader div {
  margin-bottom: 0px !important;
}

ng-multiselect-dropdown > div > div > span {
  border: 1px solid var(--bs-gray-400) !important;
  border-radius: 0.375rem !important;
}

.avatar img {
  border: 1px solid var(--bs-gray-400) !important;
}
@media only screen and (max-width: 768px) {
  .navbar.navbar-vertical.fixed-start.navbar-expand-md.navbar-light {
    position: sticky;
    top: 0px;
  }
}

input:disabled {
  color: var(--bs-gray-600) !important;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .page-fluid {
    padding-left: 0px !important;
    padding-right: 43px !important;
  }
}

.btn-container {
  position: relative;
}

.filter-btn {
  padding: 5px;
  position: relative;
  top: 20px;
  border-radius: 5px;
}

.drop-down-menu-full-width {
  width: 85vw;
  max-width: 400px;
}

.btn-close_ {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2312263F'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/0.5em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: content-box;
  color: #000000;
  height: 0.5em;
  opacity: 0.5;
  padding: 0.5em;
  width: 0.5em;
}
.btn-close_:hover {
  color: #000000;
  opacity: 0.75;
  text-decoration: none;
}
.btn-close_:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0;
}
.btn-close_.disabled,
.btn-close_:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.card-dashed {
  border-style: dashed !important;
  border-width: 1px !important;
  border-color: #000 !important;
}

.header-section {
  position: sticky;
  top: 60.5px;
  z-index: 10;
  background-color: var(--bs-body-bg);
}

@media only screen and (max-width: 425px) {
  .header-section {
    top: 0px;
  }
}

.dropdown-list {
  box-shadow: none !important;
  border: 1px solid var(--bs-gray-400) !important;
}

.dropdown-list .filter-textbox {
  border-bottom: 1px solid var(--bs-gray-400) !important;
}

.dark-mode .dropdown-list,
.dark-mode input {
  background-color: #151b2a !important;
  color: #fff !important;
}

.dark-mode .dropdown-list .multiselect-item-checkbox:hover {
  background-color: black;
}

.dark-mode .dropdown-list .multiselect-item-checkbox div {
  color: #fff !important;
}
.badge-icon {
  width: 25px;
}

.header-title .badge,
.badges__title .badge {
  font-size: 12px !important;
}

.badge-popover {
  min-width: 250px;
  margin: 5px;
}

.article__content,
.audio__content,
.book__content,
.course__content,
.gallery__content,
.quiz__content,
.ql-editor {
  p,
  li {
    line-height: 1.8;
    font-size: 16px;
    font-weight: 300;
    color: #6a7084;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    line-height: 1.4 !important;
    margin-bottom: 5px !important;
  }
}

.article__content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    margin-bottom: 5px;
    line-height: 1.4;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em !important;
  }
  h4 {
    font-size: 1em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 56.25% !important;
}

.vjs-poster img {
  object-fit: cover !important;
}

.video-js {
  border-radius: 5px;
  overflow: hidden;
}

a.link-container {
  text-decoration: underline !important;
}

span.address-container {
  text-decoration: none !important;
  outline: 1px dashed var(--gray-400);
  cursor: pointer;
}

.search-form {
  .dropdown-menu[data-bs-popper] {
    padding-top: 20px;
    top: 131% !important;
    width: 500px !important;
    max-width: 90vw !important;
    min-height: 300px;
  }
}

.editor {
  border: none !important;
}

.editor .ql-editor {
  max-height: 600px;
  overflow-y: scroll;
  // border: none;
}

.quiz-question {
  img {
    max-width: 100%;
  }
}

.editor .ql-editor {
  min-height: 400px;
}

.editor--small {
  .ql-editor {
    min-height: 100px;
  }
}

.quiz-option {
  margin-left: 5px;
}

.ql-editor {
  font-family: Sora !important;
  p,
  ol,
  ul {
    margin-bottom: 1rem !important;
  }
}

.quiz-question,
.quiz-option {
  font-family: Sora !important;
  p,
  ol,
  ul {
    margin-bottom: 5px !important;
    font-size: 16px;
  }
}

.quiz-option {
  p,
  ol,
  ul {
    margin-bottom: 0px !important;
  }
}

.word-count {
  margin-top: 5px !important;
  color: #999;
  font-size: 15px;
}

.multiselect-dropdown {
  background-color: white;
}

.button-row {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
}

/**
  * Custom styles for componeonts
*/

.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  min-height: 200px;
  max-height: 350px;
  overflow: auto;
  left: 0;
  z-index: 1000;
  min-width: 10rem;
  padding: 0 0 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);

  &__search {
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid var(--bs-gray-400);
    position: sticky;
    top: 0;
    background-color: #fff;

    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      padding-right: 40px;
      line-height: 1.8;

      &::placeholder {
        color: #b1c2d9;
      }
    }

    &-icon {
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 19%;
      font-size: 16px;
      right: 20px;
    }
  }

  &__no-data {
    text-align: center;
    color: #b1c2d9;
  }

  &__selected,
  &__options {
    display: grid;
    row-gap: 10px;
    gap: 0.5rem;
  }

  &__selected {
    border-bottom: 1px solid var(--bs-gray-400);
    padding: 0.5rem 0.75rem;
  }

  &__item {
    display: flex;
    column-gap: 10px;
    cursor: pointer;
    padding: 0rem 0.75rem;

    &-first {
      border-bottom: 1px solid var(--bs-gray-400);
      padding: 0.5rem 0.75rem;
      cursor: pointer;

      .item-label {
        font-size: 0.9em;
        color: var(--bs-gray-600);
      }
      .item-main-label {
        display: block;
        margin-top: 2px;
      }
    }

    &__label {
      display: grid;
      span:first-child {
        font-weight: 600;
      }
      span:last-child {
        color: var(--bs-gray-600);
        font-size: 0.9em;
      }
    }

    &__checkbox {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--bs-gray-400);
      border-radius: 3px;
      cursor: pointer;

      &--checked {
        background-color: var(--blue-600);
        border-color: var(--blue-600);
        color: #fff;
      }
    }
  }
}

.custom-select {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.375rem;
  color: var(--bs-body-color);
  display: block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &__selected {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;

    &__item {
      background-color: var(--gray-200);
      border-radius: 3px;
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.75em;
      white-space: nowrap;
      &__remove {
        cursor: pointer;
      }
    }
  }

  &__item-count {
    background-color: var(--gray-200);
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    font-size: 0.8em;
  }

  &__placeholder {
    color: #b1c2d9;
    cursor: pointer;
  }

  .dropdown-icon {
    cursor: pointer;
  }
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
.content-title {
  font-size: 1.2em;
  color: var(--gray-500);
}

.content-container {
  .header {
    margin-bottom: 0px;

    .header-body {
      padding-top: 0px;
    }
  }
}

.module-actions {
  display: grid;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  grid-template-columns: max-content 1fr max-content;
}

.course-information {
  font-size: 0.85em;
  position: relative;
  color: var(--orange-500);
  background-color: var(--orange-50);
  border: 1px dashed var(--orange-500);
  padding: 20px;
  border-radius: 10px;
}

.table-responsive {
  min-height: 400px;
}

.mutator-container {
  position: relative;
  &--loading {
    &::before {
      content: "";
      position: fixed;

      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.001);
      cursor: progress;
      z-index: 1000;
    }
  }
}

.errors {
  max-width: 100%;
  width: 400px;
  display: grid;
  justify-content: center;

  &-container {
    display: flex;
    align-items: center;
    min-height: 82vh;
    justify-content: center;
  }

  &__description {
    font-size: 0.9em;
    text-align: center;
    color: var(--gray-400);
  }
}

.related-row {
  margin-top: 20px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h3 {
      margin-bottom: 0px;
    }
  }

  &__list {
    &-container {
      display: flex;
      gap: 20px;
      overflow: auto;
      margin-right: 20px;
      width: 100%;
    }
    display: grid;
    grid-template-columns: repeat(3, minmax(360px, 90vw));
    gap: 10px;
    grid-auto-flow: column;
    margin-right: 20px;
    width: max-content;
  }
}

.form-check-input[type="checkbox"] {
  min-width: 16px;
  min-height: 16px;
}

.navbar-vertical.navbar-expand-md.fixed-start {
  left: unset !important;
}
