/*Dark mode styles*/

.dark-mode main,
.dark-mode footer,
.dark-mode header,
.dark-mode {
  background-color: #211f24;
  --bs-body-bg: #0c111c;
}

.dark-mode .btn-blue {
  background-color: #00c08f;
  border-color: #00c08f;
}

.dark-mode .btn:hover {
  color: #ffffff !important;
}

.dark-mode .navbar-toggler .navbar-toggler-animation span {
  background-color: #ffffff;
}

.dark-mode .form-check-label {
  color: #ffffff !important;
}

.dark-mode .navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.dark-mode .table {
  /*color: #ffffff;*/
  --bs-table-hover-color: #00c08f;
}

.dark-mode .navbar-light .navbar-nav .nav-link.active,
.dark-mode .nav-tabs .nav-item.show .nav-link,
.dark-mode .nav-tabs .nav-link.active {
  color: #ffffff;
  font-weight: bold;
}

.dark-mode .navbar-expand-xl .navbar-nav-scroll {
  background-color: #211f24;
}

.dark-mode .navbar-expand-xl .navbar-collapse {
  /*border-top: 1px solid rgb(0 0 0 / 66%);*/
}

.dark-mode h6,
.dark-mode .h6,
.dark-mode h5,
.dark-mode .h5,
.dark-mode h4,
.dark-mode .h4,
.dark-mode h3,
.dark-mode .h3,
.dark-mode h2,
.dark-mode .h2,
.dark-mode h1,
.dark-mode .h1,
.dark-mode .text-body {
  color: #ffffff !important;
}

.dark-mode .el-notification__title,
.dark-mode .swal2-title {
  color: #0d111c !important;
}

/*.dark-mode .content h6, .dark-mode .content .h6, .dark-mode .content h5, .dark-mode .content .h5, .dark-mode .content h4, .dark-mode .content .h4, .dark-mode .content h3, .dark-mode .content .h3, .dark-mode .content h2, .dark-mode .content .h2, .dark-mode .content h1, .dark-mode .content .h1 {
  color: #24292d;
}*/

.dark-mode .content .card-title,
.dark-mode .content .card-body p,
.dark-mode .content .card-body label {
  color: #ffffff !important;
}

.dark-mode .modal-title,
.dark-mode .modal-body h5,
.dark-mode .el-notification__title {
  color: #ffffff;
}

.dark-mode .card-body span {
  color: #ffffff !important;
}

.dark-mode .text-dark {
  color: #8c8c8c !important;
}

.dark-mode pre {
  color: #ffffff;
}

.dark-mode .navbar-light {
  background-color: #0d111c;
  border-color: rgb(27, 34, 54);
}

.dark-mode body,
.dark-mode .dropdown-menu,
.dark-mode .offcanvas {
  background-color: #0d111c;
  color: #ffffff;
}

.dark-mode .card-body {
  color: #ffffff;
}

.dark-mode .card,
.dark-mode .dropdown-menu-card {
  box-shadow: var(--bs-card-box-shadow);
  background-color: #0d111c !important;
  color: rgb(152, 161, 192) !important;
  border: 1px solid rgb(27, 34, 54) !important;
}

.dark-mode .card-dashed {
  border-style: dashed !important;
  border-width: 1px !important;
}

.dark-mode .list-group {
  --bs-list-group-color: #ffffff;
  --bs-list-group-border-color: rgb(27, 34, 54) !important;
}

.dark-mode .card-header,
.dark-mode .header-body {
  border-bottom: 1px solid rgb(27, 34, 54) !important;
}

.dark-mode .dropdown-divider,
.dark-mode hr {
  border-color: rgb(27, 34, 54) !important;
}

.dark-mode .card-footer {
  border-top: 1px solid rgb(27, 34, 54) !important;
}

.dark-mode .btn-primary,
.dark-mode .btn-outline-primary {
  color: #fff;
  --bs-btn-bg: #000;
  border: 1px solid #fff;
}

.form-switch .form-check-input:checked {
  border: 1px solid #fff;
}

.dark-mode .btn-white:hover {
  color: #000000 !important;
}

.dark-mode .btn-check:checked + .btn,
.dark-mode .btn-check + .btn:hover {
  background-color: white;
  color: #000 !important;
}

.dark-mode .table {
  --bs-table-color: #ffffff;
  --bs-table-bg: transparent;
  --bs-table-border-color: rgb(27, 34, 54) !important;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: var(--bs-gray-100);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: var(--bs-gray-100);
  --bs-table-hover-color: #ffffff;
  --bs-table-hover-bg: #000000;
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}

.dark-mode .table a:link,
.dark-mode .btn-close {
  color: #fff !important;
}

.dark-mode .btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/0.5em auto no-repeat;
}

.dark-mode a {
  color: #fff;
}

.dark-mode .modal-content {
  background-color: transparent;
}

.dark-mode .modal-content {
  background-color: #0d111c !important;
  --bs-modal-header-border-color: rgb(27, 34, 54);
  --bs-accordion-border-color: rgb(27, 34, 54);
  --bs-modal-footer-border-color: rgb(27, 34, 54);
}

.dark-mode .modal-content .accordion {
  --bs-accordion-bg: #0d111c;
  --bs-accordion-border-color: rgb(27, 34, 54);
}

.dark-mode .daterangepicker {
  background-color: #0d111c;
  color: #fff;
}

.dark-mode .daterangepicker .calendar-table {
  background-color: #0d111c;
  color: #fff;
  border: 1px solid #0f1114 !important;
}

.dark-mode .daterangepicker .calendar-table .next span,
.dark-mode .daterangepicker .calendar-table .prev span {
  border-color: #fff;
}

.dark-mode .daterangepicker td.off,
.dark-mode .daterangepicker td.off.in-range,
.dark-mode .daterangepicker td.off.start-date,
.dark-mode .daterangepicker td.off.end-date {
  background-color: #0d111c;
  color: #28334f;
}

.dark-mode .daterangepicker td.in-range {
  background-color: #367ebd;
  color: #fff;
}

.dark-mode .daterangepicker td.available:hover,
.dark-mode .daterangepicker th.available:hover {
  background-color: #131f3a;
}

.dark-mode .daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid rgb(27, 34, 54);
}
.dark-mode .daterangepicker .drp-buttons {
  border-top: 1px solid rgb(27, 34, 54);
}

.daterangepicker {
  overflow: hidden;
}

.dark-mode #reportrange {
  background: #151b2a !important;
  color: white !important;
  border: 1px solid #0f1114 !important;
}

.dark-mode .daterangepicker {
  border: 1px solid rgb(27, 34, 54);
}

.dark-mode .daterangepicker .ranges li:hover {
  background-color: rgb(27, 34, 54);
}

.daterangepicker .drp-buttons .btn {
  color: white;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--bs-btn-active-bg) !important;
  border-color: var(--bs-btn-active-border-color) !important;
  color: var(--bs-btn-active-color) !important;
}

.dark-mode .accordion-button {
  color: white;
}

.dark-mode label {
  color: white;
}

.dark-mode .form-control,
.dark-mode .form-select {
  background: #151b2a;
  color: white;
  /*border: 1px solid #0f1114;*/
}

.dark-mode .input-group-text {
  background-color: #0d111c;
}

.dark-mode .badge {
  background-color: #0d111c !important;
  border: 1px solid #ffffff;
}

.dark-mode a:hover {
  color: #bbb !important;
}

.dark-mode .table thead th {
  background-color: #000000 !important;
}

.dark-mode table.dataTable tbody tr {
  background-color: transparent !important;
}

.dark-mode table.dataTable tbody tr:hover {
  background-color: #000000 !important;
}

.dark-mode div.dataTables_wrapper div.dataTables_length label,
.dark-mode div.dataTables_wrapper div.dataTables_info,
.dark-mode div.dataTables_wrapper div.dataTables_filter label {
  color: #ffffff;
}

.dark-mode .offcanvas-header {
  border-bottom: none;
}

.dark-mode .search-keyword {
  color: #ffffff;
}

.dark-mode .ngx-pagination a,
.dark-mode .ngx-pagination button {
  color: #ffffff;
}

.dark-mode .ngx-pagination a:hover,
.dark-mode .ngx-pagination button:hover {
  color: #000000 !important;
}

.dark-mode div.dataTables_wrapper div.dataTables_filter input {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  outline: none;
  color: #fff;
  padding: 1px 5px;
}

.dark-mode table.dataTable.no-footer {
  border-bottom: 1px solid rgb(27, 34, 54) !important;
}

.dark-mode .nav-tabs .nav-link.active {
  border-color: #fff;
}

.dark-mode .btn-white {
  color: #000000 !important;
}

.dark-mode .btn-outline-primary {
  --bs-btn-border-color: #ffffff !important;
}
